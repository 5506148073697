<template>
    <div class="navbar-container">
        <nav class="navbar navbar-expand-sm navbar-light bg-light">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item px-2">
                            <router-link class="nav-link" to="/">Galerie</router-link>
                        </li>
                        <li class="nav-item px-2">
                            <router-link class="nav-link" to="/about-us">Eva & Johann</router-link>
                        </li>
                        <li class="nav-item px-2">
                            <router-link class="nav-link" to="/exhibitions">Ausstellungen</router-link>
                        </li>
                        <li class="nav-item px-2">
                            <router-link class="nav-link" to="/contact">Kontakt</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>
    
<script>
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default {
    name: 'NavigationBarComponent',
};
</script>
  
<script setup>
</script>
  
<style lang="scss">
.navbar {
    opacity: 0.8;
}

.navbar-nav {
    display: flex;
    align-items: center;
}

.navbar-container {
    border-bottom-color: #6542422e;
    border-bottom-style: solid;
}
</style>