<template>
    <div class="contact-view-container">
        <h1>Kontakt</h1>
        <FormComponent />
        <div>
            Falls du ein Auge auf eines oder mehrere unserer Gemälde geworfen hast, zögere nicht, uns anzusprechen.
            Die Preise teilen wir gerne auf Nachfrage mit, die sich hauptsächlich nach Größe und dem Aufwand an Zeit und
            Material richten.

            Solltest du dich zum Kauf entscheiden, kannst du dein neues Kunstwerk gerne persönlich bei uns abholen oder wir
            schicken es dir kostenfrei gut und sicher eingepackt per Post zu.
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactView",
};
</script>

<script setup>
import FormComponent from "../components/FormComponent.vue"
</script>

<style lang="scss"></style>