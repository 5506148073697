import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App';
import GalleryView from './views/GalleryView.vue';
import ContactView from './views/ContactView.vue';
import AboutUsView from './views/AboutUsView.vue';
import ExhibitionsViewVue from './views/ExhibitionsView.vue';


import { Image } from 'ant-design-vue';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const routes = [
    {
        path: "/",
        name: 'main',
        component: GalleryView,
    },
    {
        path: "/contact",
        name: 'contact',
        component: ContactView,
    },
    {
        path: "/about-us",
        name: 'about-us',
        component: AboutUsView,
    },
    {
        path: "/exhibitions",
        name: 'exhibitions',
        component: ExhibitionsViewVue,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

createApp(App).use(Image).use(router).mount('#app');
