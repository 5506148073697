<template>
    <div class="form-container">
        <form :action="FORM_ENDPOINT" @submit="handleSubmit" method="POST" target="_blank" class="form">
            <div class="form-input">
                <input name="Name" id="name" type="text" required placeholder="Name" />
            </div>

            <div class="form-input">
                <input name="Telefon" id="phone" type="text" placeholder="Telefon oder E-Mail" />
            </div>

            <div class="form-input">
                <textarea name="Nachricht" id="message" type="text" placeholder="Nachricht" required />
            </div>

            <button type="submit">Senden</button>
        </form>
    </div>
</template>

<script>
export default {
    name: "FormComponent"
}
</script>

<script setup>
import { ref } from 'vue';

const submitted = ref(false);
const FORM_ENDPOINT = ref("https://public.herotofu.com/v1/d9185830-9dca-11ee-9a19-ad506cf33f4e");

const handleSubmit = () => {
    setTimeout(() => {
        submitted.value = true;
    }, 100);
};
</script>

<stlye lang="scss">
.form-container {
    display: flex;
    flex-direction: column;
    padding: 1em;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    .form-input {
        display: flex;
        flex-direction: column;
        padding: 1em;

        textarea {
            min-height: 10em;
            padding-left: 0.5em;
        }

        input {
            padding-left: 0.5em;
        }
    }

    button {
        margin: 1em;
        border-width: thin;
    }
}
</stlye>