<template>
    <div class="gallery">
        <h1>Galerie</h1>
        <div class="columns">
            <div class="column odds">
                <div v-for="(image, index) in images" :key="index">
                    <div v-if="showImageForOdds(index)" class="image-container">
                        <a-image :preview="{ src: image.url }" :src="getImageInfoPreview(image.url)" alt="Gallery Image" />
                        <div class="image-description">
                            <div>{{ getImageInfo(image).name }}</div>
                            <div>{{ getImageInfo(image).size }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column evens">
                <div v-for="(image, index) in images" :key="index">
                    <div class="image-container" v-if="index % 2">
                        <a-image :preview="{ src: image.url }" :src="getImageInfoPreview(image.url)" alt="Gallery Image" />
                        <div class="image-description">
                            <div>{{ getImageInfo(image).name }}</div>
                            <div>{{ getImageInfo(image).size }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getData, imageData } from "../data.js";

const images = ref(null);

onMounted(() => {
    images.value = imageData;
})

const getImageInfo = (imageUrl) => {
    return getData(imageUrl.name);
}

const getImageInfoPreview = (imageUrl) => {
    const lastSlashIndex = imageUrl.lastIndexOf("/");
    const result = imageUrl.substring(0, lastSlashIndex);
    return result + '/-/quality/smart_retina/';
}

const showImageForOdds = (index) => {
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if (width <= 800) {
        return true;
    }
    return !(index % 2);
}

</script>
<style lang="scss">
.gallery {
    display: flex;
    flex-direction: column;

    .columns {
        display: flex;
        flex: 1;
        flex-direction: row;
        gap: 4em;
        padding-left: 1em;
        padding-right: 1em;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 800px) {
            flex-direction: column;
        }

        .column {
            flex: 1;
            display: flex;
            flex-direction: column;
            min-width: 150px;

            .image-container {
                margin-bottom: 2em;
                margin-left: 1em;
                margin-right: 1em;

                .ant-image {
                    box-shadow: 5px 5px 12px 3px;
                    color: gray;
                    margin-bottom: 10px;
                }

                .image-description {
                    display: flex;
                    justify-content: center;
                    gap: 20px
                }
            }
        }

        .evens {
            @media (max-width: 800px) {
                display: none
            }
        }
    }
}
</style>