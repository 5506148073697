<template>
    <div class="exhibitions-view-container">
        <h1>Ausstellungen</h1>
        <a-image :src=sparkasseUrl :preview="false"/>
    </div>
</template>

<script setup>
const sparkasseUrl = require('../assets/images/ausstellungSparkasse.jpg')
</script>

<style lang="scss">
img {
    max-width: 800px;
}
</style>