export function getData(name) {
    return imageData.find(el => el.name === name);
}

export const imageData = [
    {
        url: "https://ucarecdn.com/ec6de0a9-45ea-4368-b7bb-aebf16002602/bild1.jpg",
        name: "Bild 1",
        size: "66 x 86"
    },
    {
        url: "https://ucarecdn.com/e0a8201a-36c6-477b-ba1f-ec39d70b2825/bild2.jpg",
        name: "Bild 2",
        size: " 59 x 84"
    },
    {
        url: "https://ucarecdn.com/8cb7f5cd-fb40-4254-8f44-30bd5f8ac3c3/bild3.jpg",
        name: "Bild 3",
        size: ""
    },
    {
        url: "https://ucarecdn.com/8b82dc84-1d86-431e-b028-f80285773c04/bild4.jpg",
        name: "Bild 4",
        size: "80 x 100"
    },
    {
        url: "https://ucarecdn.com/c46f0068-e6ab-41cf-85b1-43e4dd815c23/bild5.jpg",
        name: "Bild 5",
        size: "70 x 90"
    },
    {
        url: "https://ucarecdn.com/ad87c525-87dc-4b4e-88cf-eae999fee26c/bild6.jpg",
        name: "Bild 6",
        size: "80 x 86"
    },
    {
        url: "https://ucarecdn.com/850489e1-dfb0-4c7d-8c37-8578fb6cc69a/bild7.jpg",
        name: "Bild 7",
        size: "92 x 70"
    },
    {
        url: "https://ucarecdn.com/a6c550ad-99ed-41c8-ba51-d3c8b7d9c215/bild8.jpg",
        name: "Bild 8",
        size: "80 x 120"
    },
    {
        url: "https://ucarecdn.com/c240c6d4-f61b-4ddb-9204-370ea379ec8b/bild9.jpg",
        name: "Bild 9",
        size: "67 x 89"
    },
    {
        url: "https://ucarecdn.com/7a5aa101-0189-40ab-a004-d2b2a0f87c26/bild10.jpg",
        name: "Bild 10",
        size: "78 x 89"
    },
    {
        url: "https://ucarecdn.com/c5879ec7-582d-400c-ac72-b4ae46b465ac/bild11.jpg",
        name: "Bild 11",
        size: "78 x 71"
    },
    {
        url: "https://ucarecdn.com/3f18650a-da04-463f-b443-31e454e1c817/bild12.jpg",
        name: "Bild 12",
        size: "56 x 71"
    },
    {
        url: "https://ucarecdn.com/822ea182-c813-4553-865c-03e4dace518d/bild13.jpg",
        name: "Bild 13",
        size: "58 x 66"
    },
    {
        url: "https://ucarecdn.com/98bba05e-d7c4-4530-9936-094a4946da97/bild14.jpg",
        name: "Bild 14",
        size: "65 x 52"
    },
    {
        url: "https://ucarecdn.com/39fc2b36-4c38-469c-a0b7-69277f49433f/bild15.jpg",
        name: "Bild 15",
        size: "60 x 80"
    },
    {
        url: "https://ucarecdn.com/3dd9ff6c-fafc-4888-9bee-c3b2dabffcc4/bild16.jpg",
        name: "Bild 16",
        size: "80 x 80"
    },
    {
        url: "https://ucarecdn.com/0fbd0468-ac7d-4dc7-bc28-7c89029d1c54/bild17.jpg",
        name: "Bild 17",
        size: "75 x 117"
    },
    {
        url: "https://ucarecdn.com/2378f4c8-339d-4380-906e-bab62fb6a6b1/bild18.jpg",
        name: "Bild 18",
        size: "56 x 71"
    },
    {
        url: "https://ucarecdn.com/c7f8e164-9df4-4bc8-ac25-9547609780a9/bild19.jpg",
        name: "Bild 19",
        size: "78 x 61"
    },
    {
        url: "https://ucarecdn.com/4796acc3-f694-4351-80d8-20b0a4d4a857/bild20.jpg",
        name: "Bild 20",
        size: ""
    },
    {
        url: "https://ucarecdn.com/0be0577f-0a44-4d62-a63c-92a3dde10244/bild21.jpg",
        name: "Bild 21",
        size: "100 x 150"
    },
    {
        url: "https://ucarecdn.com/40ac8415-41f4-40db-b2df-fdde807d06bf/bild22.jpg",
        name: "Bild 22",
        size: "75 x 100"
    },
    {
        url: "https://ucarecdn.com/05525b2d-540c-4913-a190-d7bb67417985/bild23.jpg",
        name: "Bild 23",
        size: "65 x 27"
    },
    {
        url: "https://ucarecdn.com/4b364645-fa58-424c-a8ef-08daf9d3cc7b/bild24.jpg",
        name: "Bild 24",
        size: "57 x 72"
    },
    {
        url: "https://ucarecdn.com/7e17de37-625d-47d8-be22-5abad0185805/bild25.jpg",
        name: "Bild 25",
        size: ""
    },
    {
        url: "https://ucarecdn.com/461fecfd-6519-4c59-bfb5-8eab79b8e283/bild26.jpg",
        name: "Bild 26",
        size: "80 x 120"
    },
    {
        url: "https://ucarecdn.com/5be59a96-541d-4fdc-b05c-355802fccf45/bild27.jpg",
        name: "Bild 27",
        size: "68 x 53"
    },
    {
        url: "https://ucarecdn.com/6b9e39b0-39d8-497e-8ed4-7455b807c981/bild28.jpg",
        name: "Bild 28",
        size: "60 x 80"
    },
    {
        url: "https://ucarecdn.com/3ed66fba-14c7-44d4-8208-4eac51522b02/bild29.jpg",
        name: "Bild 29",
        size: "56 x 73"
    },
    {
        url: "https://ucarecdn.com/afaed363-5bc4-4737-a6d0-9836f650fd31/bild30.jpg",
        name: "Bild 30",
        size: "53 x 67,5"
    },
    {
        url: "https://ucarecdn.com/c4e631af-8f5c-4241-96b3-7006aec5d155/bild31.jpg",
        name: "Bild 31",
        size: "80 x 120"
    },
    {
        url: "https://ucarecdn.com/a8fd8b27-0b6f-4130-8e44-57549f678073/bild32.jpg",
        name: "Bild 32",
        size: "80 x 120"
    },
    {
        url: "https://ucarecdn.com/ef7d40b8-3b24-419b-9447-4cc038c3faa2/bild33.jpg",
        name: "Bild 33",
        size: " 50 x 100"
    },
    {
        url: "https://ucarecdn.com/121c6eba-e259-4659-af52-c27f1b37b53e/bild34.jpg",
        name: "Bild 34",
        size: "70 x 52"
    },
    {
        url: "https://ucarecdn.com/5ad0b776-66aa-499a-bf0e-865891465d48/bild35.jpg",
        name: "Bild 35",
        size: "56 x 63"
    },
    {
        url: "https://ucarecdn.com/ecfc62b5-6b35-43d2-af1f-84954aae79e5/bild36.jpg",
        name: "Bild 36",
        size: "56 x 100"
    },
    {
        url: "https://ucarecdn.com/967a44f4-0512-4007-92c9-7d364e759526/bild37.jpg",
        name: "Bild 37",
        size: "80 x 120"
    },
    {
        url: "https://ucarecdn.com/8c456f2c-9148-4024-821c-1f243a43c3d2/bild38.jpg",
        name: "Bild 38",
        size: "62 x 51"
    },
    {
        url: "https://ucarecdn.com/6059316c-1c27-4532-9521-e5babaca5cd0/bild39.jpg",
        name: "Bild 39",
        size: ""
    },
    {
        url: "https://ucarecdn.com/71757f2c-020f-422a-84eb-cda6a30895f3/bild40.jpg",
        name: "Bild 40",
        size: "51 x 60"
    },
    {
        url: "https://ucarecdn.com/8baf8098-94a4-485b-a7b3-dc6e605a7334/bild41.jpg",
        name: "Bild 41",
        size: "73 x 57"
    },
    {
        url: "https://ucarecdn.com/9590d1c0-b62d-478b-8405-9daed4048774/bild42.jpg",
        name: "Bild 42",
        size: "60 x 70"
    },
    {
        url: "https://ucarecdn.com/3c17beef-100a-48d9-a150-472349799481/bild43.jpg",
        name: "Bild 43",
        size: "80 x 120"
    },
    {
        url: "https://ucarecdn.com/ea13aaff-2a11-4d75-a6b0-fcd58b692c0e/bild44.jpg",
        name: "Bild 44",
        size: "60 x 80"
    },
    {
        url: "https://ucarecdn.com/66dd9adc-c7e4-4e7d-a4c5-9514cfd507b3/bild45.jpg",
        name: "Bild 45",
        size: "70 x 90"
    },
]