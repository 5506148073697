<template>
  <NavigationBarComponent class="NavigationBarComponent" />
  <div class="content">
    <router-view />
  </div>
</template>

<script>
import NavigationBarComponent from "./components/NavigationBarComponent.vue"

document.title = "Bad Ischler Künstler"

export default {
  name: 'App',
  components: {
    NavigationBarComponent
  }
}
</script>

<style lang="scss">
#app {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
  font-size: large;

  .content {
    margin-left: 4vw;
    margin-right: 4vw;

    @media (max-width: 800px) {
      margin-left: 2vw;
      margin-right: 2vw;
    }
  }
}

h1 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

#app,
::placeholder,
button,
input,
textarea {
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-style: italic;
  color: rgb(101 66 66 / 65%);
}

button {
  font-weight: bold;
}

.app-container {
  display: flex;
  margin-top: 3em;

  .NavigationBarComponent {
    margin-top: 2em;
    margin-left: 2em;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
}
</style>
