<template>
    <div class="about-us-view-container">
        <h1>Eva & Johann</h1>
        <div class="text">Willkommen auf einer Entdeckungsreise durch unsere Gemäldesammlung, die aus Leidenschaft und Hingabe entstanden
            ist, um die reiche kulturelle Essenz des malerischen Salzkammerguts und der charmanten Stadt Bad Ischl
            einzufangen. Hier, in unserer kleinen, aber feinen Sammlung, wollen wir unsere Liebe zur Kunst mit
            euch teilen, inspiriert von der atemberaubenden Schönheit des Salzkammerguts, dem Gemeinschaftsgeist Bad Ischls,
            den Facetten des täglichen Lebens sowie den vielfältigen Einflüssen unserer ungarischen und rumänischen Wurzeln.
        </div>

        <a-image src="https://ucarecdn.com/2e33262a-cc0e-41dd-b8d3-b5080974aafd/" alt="Ueber uns" :preview="false"/>

        <div class="text">Sollte eines unserer Kunstwerke dein Herz erwärmen oder solltest du dich danach sehnen, ein Stück dieser kulturellen
            Pracht in deinen eigenen vier Wänden willkommen zu heißen, scheue dich nicht, uns ein Zeichen zu geben. Wir
            haben immer ein offenes Ohr und freuen uns über jedes Gespräch, egal ob es um ein persönliches Kunstwerk für
            daheim geht oder um die Zusammenstellung einer mit dir abgestimmten Ausstellung.</div>
    </div>
</template>

<script setup>
</script>

<style lang="scss">
.text,
.ant-image {
    margin-bottom: 40px;
}
</style>